import React from "react";
import { useTranslation } from "react-i18next";

import { appURLs } from "@/app/app-urls.mjs";
import { REGION_LIST } from "@/game-lol/constants/constants.mjs";
import { Footer } from "@/shared/SeasonReview.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

function SeasonReviewSearch() {
  const { t } = useTranslation();
  const {
    parameters: [season],
  } = useRoute();

  return (
    <Footer
      currentRegion=""
      titleOverride={t(
        "lol:seasonYearRecapIsHere",
        "Season 2023 Recap is here",
        { season: 2023 },
      )}
      regions={REGION_LIST}
      linkConstructor={(region, name) =>
        `/lol/season-review/${region}/${name}/${season}`
      }
      backgroundImage={`${appURLs.CDN}/blitz/lol/misc/champions-hero-splash.webp`}
    />
  );
}

export default SeasonReviewSearch;
